<template>
    <main>
        <HeaderTab :title="$t('mouvement.suggestion')" :swiper_tabs="swiper_tabs" />

		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    export default {
		name: "Suggestion",
		data () {
			return {
				swiper_tabs: [{
					id: 'stallion',
					label: 'mouvement.mes_etalons',
					active: false,
					href: 'SuggestionMouvementStallion'
				},{
					id: 'mare',
					label: 'mouvement.mes_juments',
					active: false,
					href: 'SuggestionMouvementMare'
				}]
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
		},
    }
</script>